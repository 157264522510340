<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <form ref="closingMarketForm" :action="cmUrl" target="closing-market-iframe" method="post" v-if="showClosingMarket">
            <input type="hidden" name="userName" id="userName" :value="user.cmUsername" />
            <input type="hidden" name="password" id="password" :value="user.cmPassword">
            <input type="hidden" name="orderID" id="orderID" :value="order.gfNo">
            <input type="hidden" name="orderDataFormat" id="orderDataFormat" value="RamQuestONE">
            <input type="hidden" name="RamQuestProduct" id="RamQuestProduct" value="RQONE">
            <input type="submit" value="post" v-show="false">
        </form>
        <div class="closing-market-frame-container" v-if="showClosingMarket">
            <iframe name="closing-market-iframe" id="closing-market-iframe" src="about:blank" class="closing-market-iframe" seamless></iframe>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { SYSTEM_ACTIONS } from "@/store/actions";

    export default {
        name: "ClosingMarket",
        components: {
        },
        data() {
            return {
                errorMessage: "",
                cmUrl: null,
                isValid: false,
                user: { cmUsername: null, cmPassword: null }
            }
        },
        computed: {
            ...mapGetters([
                "currentSession"
            ]),
            ...mapState({
                cmPluginURL: state => state.system.systemDefaults.cmPluginURL,
                order: state => state.orders.order,
            }),
            currentUserId() { return this.currentSession.userId; },
            hasValidOrderID() { return _.get(this.order, 'ordersID', 0) > 0; },
            hasCredentials() { return !_.isNullOrEmpty(_.get(this.user, 'cmUsername', null)) && !_.isNullOrEmpty(_.get(this.user, 'cmPassword', null)); },
            showClosingMarket() { return this.hasCredentials && this.hasValidOrderID && !_.isNullOrEmpty(this.cmUrl); },
            closingMarketForm() { return _.get(this, "$refs.closingMarketForm", null) || {}; },
        },
        beforeUnmount() { this.endWait(); },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                const self = this;
                if (_.size(self.cmPluginURL) === 0) {
                    self.errorMessage = "Closing Market URL has not been set, please see your admin.";
                    setTimeout(() => {self.reinit();}, 500);
                    return;
                } else {
                    let url = new URL(self.cmPluginURL);
                    self.cmUrl = url.href;
                }
                let apiPromise = self.$api.UsersApi.getUncachedUser(self.currentUserId);
                return self.$rqBusy.wait(apiPromise)
                    .then(user => {
                        self.user = user;
                        self.$nextTick(() => {
                            self.init();
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        self.$toast.error({ message: `Error loading user data.` });
                        return error;
                    });
            },
            
            init() {
                const self = this;
                
                if (!self.hasValidOrderID) {
                    self.errorMessage = "Current Order is not Valid.";
                    return;
                }
                if (!self.hasCredentials) {
                    self.errorMessage = "You do not have the proper credentials to log into Closing Market, please see your admin.";
                    return;
                }
                try {
                    self.closingMarketForm.submit();
                }
                catch(err) {
                    self.errorMessage = "Closing Market URL is invalid, please see your admin.";
                    return;
                }
            },
            reinit(){
                //attempt to reload state
                const self = this;
                let promise = self.$store.dispatch(SYSTEM_ACTIONS.GET_SYSTEM_DEFAULTS, true);
                self.$rqBusy.wait(promise).then((result) => {
                    if(_.size(result.systemDefault.cmPluginURL) === 0){
                        self.errorMessage = "Closing Market URL has not been set, please see your admin.";
                        return;
                    }
                    try {
                        let url = new URL(result.systemDefault.cmPluginURL);
                        self.cmUrl = url.href;
                        self.$nextTick(() => {
                            self.closingMarketForm.submit();
                        });
                    }
                    catch(err) {
                        self.cmUrl = null;
                        self.errorMessage = "Closing Market URL is invalid, please see your admin.";
                        return;
                    }
                });
            },
            endWait() { this.$rqBusy.endWait(true); },
            startWait() { this.$rqBusy.startWait(true); },
        }
    }
</script>