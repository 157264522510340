import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    activate (ids) {
        return HttpWrapper.post(`${endpoints.USERS}/activate`, ids);
    },
    copyGroup (group) {
        return HttpWrapper.put(`${endpoints.USERS}/copyGroup`, group );
    },
    copyUser (user) {
        return HttpWrapper.put(`${endpoints.USERS}/copyUser`, user );
    },
    getUsers() {
        return HttpWrapper.get(`${endpoints.USERS}`);
    },
    getUsersByApplicationAccess(applicationAccessColumn, userID) {
        return HttpWrapper.get(`${endpoints.USERS}/applicationAccess/${applicationAccessColumn}/${userID}`);
    },
    getGroup(userGroupId) {
        return HttpWrapper.get(`${endpoints.USERS}/group/${userGroupId}`);
    },
    getGroups() {
        return HttpWrapper.get(`${endpoints.USERS}/groups`);
    },
    getUserSettings: (token=null) => {
        let cfg = (token && { headers: { Authorization: `Bearer ${token}` } }) || null;
        return HttpWrapper.get(`${endpoints.USERS}/security-settings`, cfg);
    },
    getUser(userId) {
        return HttpWrapper.get(`${endpoints.USERS}/${userId}`, null);
    },
    getUncachedUser(userId) {
        return HttpWrapper.get(`${endpoints.USERS}/${userId}/uncached`, null);
    },
    getUserRegions: userId => {
        return HttpWrapper.get(`${endpoints.USERS}/${userId}/regions`);
    },
    getUsersByRegion(regionId) {
        return HttpWrapper.get(`${endpoints.USERS}/region/${regionId}`);
    },
    getUsersByDefaultRegion(regionId) {
        return HttpWrapper.get(`${endpoints.USERS}/defaultRegion/${regionId}`);
    },
    saveUserEntity (user, changes) {
        return HttpWrapper.put(`${endpoints.USERS}/user`, { data: user, changes} );
    },
    saveUser (user, changes) {
        return HttpWrapper.put(`${endpoints.USERS}`, { data: user, changes} );
    },
    saveUserAccountSettings (userAccountSettingsDto, changes) {
        return HttpWrapper.put(`${endpoints.USERS}/accountSettings`, { data: userAccountSettingsDto, changes} );
    },    
    saveGroup (group, changes) {
        return HttpWrapper.put(`${endpoints.USERS}/group`, { data: group, changes} );
    },
    saveGroupData (groupData, changes) {
        return HttpWrapper.put(`${endpoints.USERS}/groupData`, { data: groupData, changes} );
    },
    saveSecurityRoleData (securityRoleData, changes) {
        return HttpWrapper.put(`${endpoints.USERS}/securityRoleData`, { data: securityRoleData, changes} );
    },
    addDictionaryWords(userId, words) {
        return HttpWrapper.post(`${endpoints.USERS}/dictionary/${userId}`, words);
    },
    validatePIN (usersID, pin) {
        return HttpWrapper.get(`${endpoints.USERS}/validatePIN/${usersID}/${btoa(pin)}`, null);
    },
    forcePasswordUpdateOnLogin(emailAddress) {
        return HttpWrapper.put(`${endpoints.USERS}/forceChangePasswordByEmail/${emailAddress}`);
    },
};
